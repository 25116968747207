<template>
  <v-card>
    <v-card-title>
      <span class="text-h6">{{ $t("labels.add_request") }}</span>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="5">
          <v-autocomplete
            ref="inputIndex_0"
            @change="
              nextInputFocus(1);
              removeValidate('type');
              refreshCreateData();
            "
            :error="validates.includes('type')"
            v-model="createData.type"
            :items="createTypes"
            :label="$t('labels.classify_1')"
            dense
            outlined
            hide-details
            class="c-input-small"
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" md="4">
          <v-autocomplete
            ref="inputIndex_1"
            @change="
              nextInputFocus(1);
              removeValidate('department');
            "
            :disabled="isDisableInputDepartment"
            :error="validates.includes('department')"
            v-model="createData.department"
            :items="createDepartments"
            :label="$t('labels.department_1')"
            dense
            outlined
            hide-details
            class="c-input-small"
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" md="3">
          <select-warehouse-model
            ref="inputIndex_2"
            v-model="createData.id_warehouse"
            :label="$t('labels.warehouse')"
            dense
            outlined
            clearable
            hide-details
            @change="
              nextInputFocus(2);
              removeValidate('id_warehouse');
              onWarehouseChanged();
            "
          ></select-warehouse-model>
        </v-col>

        <template v-if="createData.type">
          <v-col
            cols="12"
            v-if="[config.TYPE.PICKUP_REDUCE].includes(createData.type)"
          >
            <v-text-field
              ref="inputIndex_3"
              @keyup.enter="nextInputFocus(3)"
              @change="removeValidate('basket_code')"
              :error="validates.includes('basket_code')"
              :label="$t('labels.basket_code_1')"
              dense
              outlined
              hide-details
              class="c-input-small"
              v-model="createData.basket_code"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            v-if="[config.TYPE.PICKUP_REDUCE].includes(createData.type)"
          >
            <v-text-field
              ref="inputIndex_4"
              @keyup.enter="nextInputFocus(4)"
              @change="removeValidate('cell_id')"
              :error="validates.includes('cell_id')"
              label="Vị trí nhặt *"
              dense
              outlined
              hide-details
              class="c-input-small"
              v-model="createData.cell_id"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            v-if="
              [
                config.TYPE.RETURN_HAS_MONEY,
                config.TYPE.UPDATE_ORDER_STATUS,
                config.TYPE.RE_DELIVERY,
                config.TYPE.URGING_DELIVERY,
                config.TYPE.APPOINTMENT_DELIVERY,
                config.TYPE.CANCEL_DELIVERY,
                config.TYPE.INSPECTION_PACKING,
                config.TYPE.ORDER_CHECK,
                config.TYPE.ORDER_RETURN_CHECK,
                config.TYPE.ORDER_UPDATE_INFO,
                config.TYPE.ORDER_UPDATE_COD,
              ].includes(createData.type)
            "
          >
            <v-text-field
              ref="inputIndex_5"
              @keyup.enter="
                nextInputFocus(5);
                getOrder();
              "
              @change="removeValidate('order_tracking_id')"
              :error="validates.includes('order_tracking_id')"
              label="Tracking đơn *"
              dense
              outlined
              hide-details
              class="c-input-small"
              @blur="getOrder"
              v-model="createData.order_tracking_id"
            ></v-text-field>
          </v-col>

          <template
            v-if="[config.TYPE.ORDER_UPDATE_COD].includes(createData.type)"
          >
            <v-col cols="6">
              <input-number
                v-model.number="orderData.cash_on_delivery"
                type="number"
                class="c-input-small"
                outlined
                dense
                hide-details
                :label="$t('labels.old_cod_requets')"
                disabled
              />
            </v-col>

            <v-col cols="6">
              <input-number
                v-model.number="orderData.request_new_cod"
                ref="inputIndex_5"
                type="number"
                class="c-input-small"
                outlined
                dense
                hide-details
                @change="removeValidate('money')"
                :error="validates.includes('money')"
                label="Số tiền cần thu hộ mới *"
                placeholder="COD = 0 hoặc > 10,000"
                @input="addMessageCod"
              />
            </v-col>
          </template>

          <v-col
            cols="12"
            v-if="
              [
                config.TYPE.RETURN_HAS_MONEY,
                config.TYPE.SALARY_ADVANCE,
                config.TYPE.BONUS,
                config.TYPE.PUNISHMENT,
                config.TYPE.PRODUCT_REPAIR,
              ].includes(createData.type)
            "
          >
            <input-number
              ref="inputIndex_6"
              @keyup.enter="nextInputFocus(6)"
              @change="removeValidate('money')"
              :error="validates.includes('money')"
              :label="$t('labels.money_1')"
              dense
              outlined
              hide-details
              class="c-input-small"
              v-model.number="createData.money"
            />
          </v-col>

          <v-col
            cols="12"
            v-if="
              [config.TYPE.BONUS, config.TYPE.PUNISHMENT].includes(
                createData.type
              )
            "
          >
            <v-autocomplete
              ref="inputIndex_7"
              @change="nextInputFocus(7)"
              v-model="createData.id_identity_receipt"
              :items="employees"
              :label="`${$t('labels.select_employee_1')} *`"
              dense
              outlined
              hide-details
              class="c-input-small"
            ></v-autocomplete>
          </v-col>

          <v-col
            cols="12"
            v-if="
              [
                config.TYPE.RECEIPT_INCREASE,
                config.TYPE.RECEIPT_REDUCE,
              ].includes(createData.type)
            "
          >
            <v-text-field
              ref="inputIndex_8"
              @keyup.enter="nextInputFocus(8)"
              @change="removeValidate('receipt_tracking_id')"
              :error="validates.includes('receipt_tracking_id')"
              label="Tracking nhập kho *"
              dense
              outlined
              hide-details
              class="c-input-small"
              v-model="createData.receipt_tracking_id"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            v-if="
              [
                config.TYPE.GOODS_INCREASE,
                config.TYPE.GOODS_REDUCE,
                config.TYPE.PICKUP_REDUCE,
                config.TYPE.RECEIPT_INCREASE,
                config.TYPE.RECEIPT_REDUCE,
              ].includes(createData.type)
            "
          >
            <v-text-field
              ref="inputIndex_9"
              @keyup.enter="nextInputFocus(9)"
              @change="removeValidate('sku')"
              :error="validates.includes('sku')"
              label="SKU *"
              dense
              outlined
              hide-details
              class="c-input-small"
              v-model="createData.sku"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            v-if="
              [
                config.TYPE.GOODS_INCREASE,
                config.TYPE.GOODS_REDUCE,
                config.TYPE.PICKUP_REDUCE,
                config.TYPE.RECEIPT_INCREASE,
                config.TYPE.RECEIPT_REDUCE,
              ].includes(createData.type)
            "
          >
            <v-text-field
              ref="inputIndex_10"
              @keyup.enter="nextInputFocus(10)"
              @change="removeValidate('quantity')"
              :error="validates.includes('quantity')"
              label="Số lượng *"
              dense
              outlined
              hide-details
              class="c-input-small"
              v-model="createData.quantity"
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            v-if="
              [
                config.TYPE.LEAVE,
                config.TYPE.TIMEKEEPING_ADDITIONAL,
                config.TYPE.LEAVE_EARLY,
              ].includes(createData.type)
            "
          >
            <v-autocomplete
              ref="inputIndex_11"
              @change="
                nextInputFocus(11);
                removeValidate('working_shift');
              "
              :error="validates.includes('working_shift')"
              v-model="createData.working_shift"
              :items="workingShifts"
              :label="$t('labels.shift_1')"
              dense
              outlined
              hide-details
              class="c-input-small"
            ></v-autocomplete>
          </v-col>

          <v-col
            cols="12"
            v-if="
              [
                config.TYPE.LEAVE,
                config.TYPE.TIMEKEEPING_ADDITIONAL,
                config.TYPE.LEAVE_EARLY,
              ].includes(createData.type)
            "
          >
            <DatePicker
              :label="$t('labels.date_1')"
              name="working_date"
              @change="onWorkingDateChange"
              :min-date="minDate()"
              :key="`work_date_${createData.type}`"
              :disabled="
                [
                  config.TYPE.TIMEKEEPING_ADDITIONAL,
                  config.TYPE.LEAVE_EARLY,
                ].includes(createData.type)
              "
              dense
              outlined
              hide-details
              class="c-input-small"
            />
          </v-col>

          <template v-if="[config.TYPE.LOST_ITEM].includes(createData.type)">
            <v-col cols="3">
              <v-text-field
                :disabled="!createData.id_warehouse || lostItems.length > 0"
                dense
                outlined
                hide-details
                class="c-input-small"
                v-model="createData.sku"
                ref="inputIndex_12"
                @keyup.enter="getGoods()"
                @blur="getGoods()"
                @change="removeValidate('sku')"
                :error="validates.includes('sku')"
                label="SKU *"
              ></v-text-field>
            </v-col>
            <v-col cols="9">
              <v-text-field
                :disabled="!createData.sku"
                ref="inputIndex_13"
                label="UID | IMEI"
                :description="$t('labels.uid_separate')"
                dense
                outlined
                hide-details
                class="c-input-small"
                v-model="lostUid"
                @keyup.enter="onInputUidChange"
                @paste.prevent.stop="onUidPaste"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-simple-table class="table-padding-2">
                <template v-slot:default>
                  <thead class="v-data-table-header">
                    <tr>
                      <th
                        role="columnheader"
                        class="text-center"
                        style="width: 150px"
                      >
                        UID
                      </th>
                      <th role="columnheader" class="text-center">
                        {{ $t("labels.available") }}
                      </th>
                      <th role="columnheader" class="text-center">
                        {{ $t("labels.status") }}
                      </th>
                      <th
                        role="columnheader"
                        class="text-center"
                        style="width: 100px"
                      >
                        {{ $t("labels.for_order") }}
                      </th>
                      <th role="columnheader" class="text-center">
                        {{ $t("labels.import_1") }}
                      </th>
                      <th role="columnheader" class="text-center">
                        {{ $t("labels.finish_stowing") }}
                      </th>
                      <th
                        role="columnheader"
                        class="text-center"
                        style="width: 200px"
                      >
                        {{ $t("labels.note") }}
                      </th>
                      <th style="width: 50px"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="text-center"
                      v-for="(lostItem, lostKey) in lostItems"
                      :key="`li_${lostKey}`"
                    >
                      <td>{{ lostItem.code }}</td>
                      <td>
                        <span :class="{ 'error--text': !lostItem.available }">
                          {{ lostItem.available ? "Yes" : "No" }}
                        </span>
                      </td>
                      <td>
                        <span :class="{ 'error--text': lostItem.status != 3 }">
                          {{ statusTxt[lostItem.status] }}
                        </span>
                      </td>
                      <td>
                        <v-text-field
                          dense
                          outlined
                          hide-details
                          class="c-input-small"
                          v-model="lostItem.tracking_id"
                          :disabled="isDisableInputLostTracking(lostItem)"
                        ></v-text-field>
                      </td>
                      <td>
                        {{ lostItem.receipt_employee_name }}
                        <br />
                        <small>{{
                          lostItem.receipt_time
                            ? formatDateTime(lostItem.receipt_time)
                            : ""
                        }}</small>
                      </td>
                      <td>
                        {{ lostItem.stowing_employee_name }}
                        <br />
                        <small>{{
                          lostItem.stowing_time
                            ? formatDateTime(lostItem.stowing_time)
                            : ""
                        }}</small>
                      </td>
                      <td>
                        <v-text-field
                          dense
                          outlined
                          hide-details
                          class="c-input-small"
                          v-model="lostItem.note"
                        ></v-text-field>
                      </td>
                      <td>
                        <v-btn
                          color="warning"
                          x-small
                          @click="removeItem(lostItem.code)"
                          >Xóa</v-btn
                        >
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </template>
          <template v-else>
            <v-col cols="12">
              <v-textarea
                ref="inputIndex_14"
                @keyup.enter="nextInputFocus(14)"
                @change="removeValidate('note')"
                rows="3"
                :error="validates.includes('note')"
                :label="$t('labels.note')"
                v-model="createData.note"
                dense
                outlined
                hide-details
                class="c-input-small"
              ></v-textarea>
            </v-col>

            <v-col cols="12">
              <v-file-input
                accept="image/*"
                v-model="file"
                @change="onInputFileChange"
                append-icon="mdi-paperclip"
                prepend-icon=""
                :error="validates.includes('file')"
                dense
                outlined
                hide-details
                class="c-input-small"
              ></v-file-input>
            </v-col>
          </template>
        </template>
      </v-row>
      <div v-if="errMsg">
        <small class="red--text">{{ errMsg }}</small>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="closeDialog">
        {{ $t("labels.close") }}
      </v-btn>
      <v-btn color="primary" @click="saveInternalRequest">
        {{ $t("labels.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { compress } from "image-conversion";
import { httpClient } from "@/libs/http";
import {
  formatNumber,
  formatDateTime,
  generateRandomString,
} from "@/libs/helpers";
import {
  INTERNAL_REQUEST_DEFAULT,
  INTERNAL_REQUEST_BONUS,
  INTERNAL_REQUEST_PUNISHMENT,
  INTERNAL_REQUEST_LOST_ITEM,
  ALLOW_REQUEST_CREATE_TYPES,
  MONEY_OPTIONS,
} from "@/libs/const";
import moment from "moment";

export default {
  name: "Create",
  components: {
    DatePicker: () => import("@/components/common/DatePicker"),
  },
  props: {
    config: {
      type: Object,
      default: () => {},
    },
    types: {
      type: Array,
      default: () => [],
    },
    departments: {
      type: Array,
      default: () => [],
    },
    workingShifts: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    errMsg: null,
    createData: { ...INTERNAL_REQUEST_DEFAULT },
    orderData: {},
    validates: [],
    file: null,
    fileUrl: null,
    isLoading: false,
    moneyOptions: { ...MONEY_OPTIONS },
    employees: [],
    warehouses: [],
    lostUid: "",
    lostItems: [],
  }),
  computed: {
    createTypes() {
      let types = [...this.types].filter((t) =>
        ALLOW_REQUEST_CREATE_TYPES.includes(t.value)
      );
      if (!this.checkPermission(["internal_request_process"])) {
        types = [...types].filter(
          (t) =>
            !t.text.includes("Auto") &&
            ![
              INTERNAL_REQUEST_BONUS,
              INTERNAL_REQUEST_PUNISHMENT,
              INTERNAL_REQUEST_LOST_ITEM,
            ].includes(t.value)
        );
      }
      return types;
    },
    createDepartments() {
      return [...this.departments];
    },
    typeToDepartment() {
      return (this.config && this.config.TYPE_TO_DEPARTMENT) || {};
    },
    isDisableInputDepartment() {
      return (
        !!this.typeToDepartment[this.createData.type] || !this.createData.type
      );
    },
    statusTxt() {
      return {
        1: this.$t("labels.uid_status_1"),
        2: this.$t("labels.uid_status_2"),
        3: this.$t("labels.uid_status_3"),
        4: this.$t("labels.uid_status_4"),
        5: this.$t("labels.uid_status_5"),
        6: this.$t("labels.uid_status_6"),
        0: this.$t("labels.uid_status_0"),
      };
    },
  },
  mounted() {
    // this.getWarehouses();
  },
  methods: {
    formatDateTime,
    formatNumber,
    generateRandomString,
    isDisableInputLostTracking(lostItem) {
      if (this.checkPermission(['super_admin'])) {
        return false
      }
      return !lostItem.available || lostItem.status != 3
    },
    getWarehouses() {
      this.warehouses = window.me.warehouses.map((e) => ({
        value: e.id,
        text: e.code_name || "",
      }));
      if (this.warehouses.length === 1) {
        this.createData = {
          ...this.createData,
          id_warehouse: this.warehouses[0].value,
        };
        this.getEmployees();
      }
    },
    onWarehouseChanged() {
      this.getEmployees();
    },
    async getEmployees() {
      const { data } = await httpClient.post(
        "/employee/v1/get-by-my-warehouse",
        {
          id_warehouse: this.createData.id_warehouse,
          status: 10,
        }
      );
      this.employees = data.map((e) => ({
        value: e.id_identity,
        text: e.full_name || "",
      }));
    },
    async saveInternalRequest() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;
      this.errMsg = null;
      this.validates = [];

      if (this.createData.type == this.config.TYPE.LOST_ITEM) {
        const employeeReceipt = {};
        const employeeStowing = {};
        const rollNotes = [];
        const packNotes = [];

        this.lostItems.forEach((lostItem) => {
          if (!employeeReceipt[lostItem.id_employee_receipt]) {
            employeeReceipt[lostItem.id_employee_receipt] = {
              employee_name: lostItem.receipt_employee_name,
              uids: [],
            };
          }
          employeeReceipt[lostItem.id_employee_receipt].uids.push(lostItem.id);

          if (!employeeStowing[lostItem.id_employee_stowing]) {
            employeeStowing[lostItem.id_employee_stowing] = {
              employee_name: lostItem.stowing_employee_name,
              uids: [],
            };
          }
          employeeStowing[lostItem.id_employee_stowing].uids.push(lostItem.id);

          const itemAvailable = lostItem.available ? "Yes" : "No";
          const itemStatus = this.statusTxt[lostItem.status];
          if (lostItem.tracking_id) {
            packNotes.push(
              `${lostItem.code} - Cấp cho đơn: ${
                lostItem.tracking_id
              } - Ghi chú: ${lostItem.note || ""}`
            );
          } else {
            rollNotes.push(
              `${
                lostItem.code
              } - Khả dụng: ${itemAvailable} - Trạng thái: ${itemStatus} - Ghi chú: ${
                lostItem.note || ""
              }`
            );
          }
        });

        const notes = [];

        const receiptEmployeeIds = Object.keys(employeeReceipt);
        if (receiptEmployeeIds.length > 0) {
          notes.push(`Nhập kho:`);
          receiptEmployeeIds.forEach((id_employee) => {
            const employeeData = employeeReceipt[id_employee];
            notes.push(
              `${employeeData.employee_name}: ${employeeData.uids.length}`
            );
          });
        }

        const stowingEmployeeIds = Object.keys(employeeStowing);
        if (stowingEmployeeIds.length > 0) {
          notes.push(`Lên kệ:`);
          stowingEmployeeIds.forEach((id_employee) => {
            const employeeData = employeeStowing[id_employee];
            notes.push(
              `${employeeData.employee_name}: ${employeeData.uids.length}`
            );
          });
        }

        if (rollNotes.length > 0) {
          notes.push("Thu hồi:");
          notes.push(...rollNotes);
        }

        if (packNotes.length > 0) {
          notes.push("Đóng thiếu:");
          notes.push(...packNotes);
        }

        this.createData.note = notes.join("\n");
        this.createData.lostItems = this.lostItems;
      }
      try {
        await httpClient.post("/internal-request/v1/create", {
          ...this.createData,
          file: (this.file && this.fileUrl) || "",
        });
        this.createData = { ...INTERNAL_REQUEST_DEFAULT };
        this.closeDialog();
        this.refreshData();
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        this.errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        const validates =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.validates) ||
          [];
        if (validates && validates.length > 0) {
          validates.forEach((v) => {
            this.validates.push(v.key);
          });
        }
      }
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    refreshData() {
      this.$emit("refreshData");
    },
    removeItem(code) {
      const uids = this.lostUid.split(";");
      const newUids = [...uids].filter((uid) => uid != code);
      console.log(newUids);
      this.lostUid = newUids.join(";");
      this.onInputUidChange();
    },
    onInputUidChange() {
      const lostUids = this.lostUid.split(";");
      const duplicateCodes = [];
      const lostCodes = [];
      this.lostItems = [];
      lostUids.forEach((code, key) => {
        code = code.trim();
        const checkDuplicate = [...lostUids].find(
          (cd, ck) => cd.trim() == code && key != ck
        );
        if (checkDuplicate && !duplicateCodes.includes(code)) {
          duplicateCodes.push(code);
        } else {
          lostCodes.push(code.trim());
        }
      });
      if (duplicateCodes.length > 0) {
        this.$vToastify.error(
          this.$t("labels.duplicate_code", { code: duplicateCodes.join("; ") })
        );
        return false;
      }
      this.getUidItems(lostCodes);
    },

    async getUidItems(lostCodes) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        const { data } = await httpClient.post(
          "/goods/v1/get-uids-lost-items",
          {
            id_warehouse: this.createData.id_warehouse,
            sku: this.createData.sku,
            codes: lostCodes,
          }
        );
        this.isLoading = false;
        this.lostItems = [...data].map((item) => ({
          ...item,
          tracking_id: null,
          note: null,
        }));
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    onUidPaste(evt) {
      const value = evt.clipboardData.getData("text");
      if (value.includes("\n")) {
        const codeSplit = value.split("\n").filter((v) => v);
        this.lostUid = codeSplit.join(";");
      } else if (value.includes(" ")) {
        const codeSplit = value.split(" ").filter((v) => v);
        this.lostUid = codeSplit.join(";");
      } else {
        this.lostUid += value;
      }
    },
    refreshCreateData() {
      this.createData = {
        ...INTERNAL_REQUEST_DEFAULT,
        id_warehouse: this.createData.id_warehouse,
        type: this.createData.type,
        department: this.typeToDepartment[this.createData.type],
      };
      this.$emit("typeChange", this.createData.type);
      this.validates = [];
      this.errMsg = null;
    },
    removeValidate(name) {
      this.validates = this.validates.filter((v) => v !== name);
    },
    nextInputFocus(index) {
      if (index > 15) {
        return false;
      }
      this.$nextTick(() => {
        if (this.$refs[`inputIndex_${index + 1}`]) {
          this.$refs[`inputIndex_${index + 1}`].focus();
        } else {
          this.nextInputFocus(index + 1);
        }
      });
    },
    onInputFileChange(file) {
      if (file) {
        this.uploadFile(file);
      }
    },
    async uploadFile(file) {
      const resBlob = await compress(file, {
        quality: 0.85,
        type: "image/jpeg",
      });
      const newFile = new File(
        [resBlob],
        `${moment().unix()}_${this.generateRandomString(10)}.jpg`,
        { type: "image/jpeg" }
      );
      let fd = new FormData();
      fd.append("file", newFile);
      httpClient
        .post(`/common/v1/upload-image`, fd)
        .then((res) => {
          this.fileUrl = res.data;
        })
        .catch((err) => {
          console.log("uploadFile err", err);
        });
    },
    onWorkingDateChange(date) {
      this.createData.working_date = date;
    },
    minDate() {
      return moment().format("YYYY-MM-DD");
    },

    async getGoods() {
      if (!this.createData.sku) {
        return false;
      }

      try {
        const codeSpl = this.createData.sku.split("@");
        const sku = codeSpl[0] || "";
        await httpClient.post("/goods/v1/get-by-code-type-and-warehouse", {
          id_warehouse: this.createData.id_warehouse,
          code: sku,
          type: "sku",
        });
        this.nextInputFocus(12);
        document.getElementById("success_sound_player").play();
      } catch (e) {
        this.code = null;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        document.getElementById("error_sound_player").play();
        this.$vToastify.error(errMsg);
      }
    },

    async getOrder() {
      if (!this.createData.order_tracking_id) {
        return false;
      }
      try {
        const { data } = await httpClient.post(
          "/goods-issue-detail/v1/get-order-info-by-code",
          {
            code: this.createData.order_tracking_id,
          }
        );
        this.isLoading = false;
        if (!data || data.length === 0) {
          this.$vToastify.error(
            this.$t("messages.order_not_found", {
              tracking: this.createData.order_tracking_id,
            })
          );
          this.createData = { ...this.createData, order_tracking_id: null };
          return false;
        }
        const order = data[0];
        this.orderData = { ...order };
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
        this.createData = { ...this.createData, tracking_id: null };
      }
    },

    addMessageCod() {
      this.createData = {
        ...this.createData,
        money: this.orderData.request_new_cod,
        note: `Yêu cầu sửa COD từ ${this.formatNumber(
          this.orderData.cash_on_delivery
        )} thành ${this.formatNumber(this.orderData.request_new_cod)}`,
      };
    },
  },
};
</script>

<style scoped></style>
